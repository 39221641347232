import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';
import { axiosInstance } from '../../../../network/apis';
import { Input } from '../../../shared/Input';
import { LocaleTitle } from '../../../shared/LocaleTitle';
import { TextArea } from '../../../shared/textarea';
import { IFeatures, IResponseAdminPlans } from './types';
import { Form, FormikProvider, useFormik } from 'formik';
import PlansInCreate from './components/plansInCreate';
import { toast } from 'react-toastify';
import { useSubscriptionPlansWrapperSchema } from './useSubscriptionPlansWrapperSchema';
import { Label } from '../../../shared/Label';
import { OptionProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { debounce } from '../../../../utils/debounce';
import { LOCALE } from '../../../../_metronic/helpers/typescript';
import Select from 'react-select';
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';
import { PlanTypeMap } from '../../../../_metronic/constants/general'; //DocumentDirection,

interface IParams {
  subscriptionPlanId: string;
}
type Props = {
  mode: 'create' | 'edit' | 'view';
};
export const SubscriptionPlansWrapper: FC<Props> = ({ mode }) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const params: IParams = useParams();
  const { locale } = useIntl();
  const history = useHistory();
  const { SubscriptionPlansWrapperSchema } = useSubscriptionPlansWrapperSchema();
  const { formatMessage } = useIntl();
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];
  /* ------------------------------ Local States ------------------------------ */
  const [displayed, setDisplayed] = useState<Array<number>>([0, 1]);
  const [subscribedPlans, setSubscribedPlans] = useState<IFeatures[]>([]);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState<IResponseAdminPlans>();

  /* ---------------------------------- APIS ---------------------------------- */
  const formik = useFormik({
    initialValues: {
      name_ar: '',
      name_en: '',
      description_ar: '',
      description_en: '',
      commercial_description_ar: '',
      commercial_description_en: '',
      downgrade_plans: [],
      features: [],
      monthly_price: '', // Add this line
      yearly_price: '', // Add this line
      status: '0',
      type: 1,
      invoice_period: '',
      for_embedded_app: false,
      order: {
        label: '',
        value: 1,
      },
    },
    validationSchema: SubscriptionPlansWrapperSchema,
    onSubmit: (values, { resetForm }) => {
      if (mode === 'view') {
        return;
      }
      if (mode === 'edit') {
        editSubscriptionPlan(values, resetForm);
      }
      if (mode === 'create') {
        createSubscriptionPlan(values, resetForm);
      }
    },
  });
  /* -------------------------------- Functions ------------------------------- */
  const createSubscriptionPlan = (values: any, resetForm: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name[ar]', values.name_ar);
    formData.append('name[en]', values.name_en);
    formData.append('description[ar]', values.description_ar);
    formData.append('description[en]', values.description_en);
    formData.append('commercial_description[ar]', values.commercial_description_ar);
    formData.append('commercial_description[en]', values.commercial_description_en);
    formData.append('for_embedded_app', values.for_embedded_app ? '1' : '0');
    formData.append('order', values.order.value);

    formData.append('type', `${values.type}`);

    // plan types conditions
    if (values.type === 1) {
      formData.append('is_active', values.status);
      formData.append('prices[0][period_type]', '1'); //monthly
      formData.append('prices[0][price]', values.monthly_price); //monthly

      formData.append('prices[1][period_type]', '2'); // yearly
      formData.append('prices[1][price]', values.yearly_price); //yearly
    } else if (values.type === 3) {
      formData.append('invoice_period', values.invoice_period);
    }
    if (values.downgrade_plans.length > 0) {
      formData.append('fallback_id', (values.downgrade_plans[0] as any)?.value);
    }
    values?.features?.forEach((input: any, index: any) => {
      if (input && input.value) {
        formData.append(`features[${index}][value]`, input.value === true ? '1' : input.value);
      }
    });

    axiosInstance
      .post(`/admin/plans`, formData)
      .then((response) => {
        setLoading(false);
        toast.success(formatMessage({ id: 'subscription has been create' }), {
          toastId: 'subscription has been create',
        });
        history.push(`/admin/subscription-plans`);
      })
      .catch((err) => {
        setLoading(false);
        const data = err?.response?.data?.errors;
        const mergedErrors: any = Object.values(data).reduce(
          (accumulator: any, currentValue) => accumulator.concat(currentValue),
          []
        );
        mergedErrors.map((error: string, index: number) => toast.error(error, { toastId: index }));
      });
    resetForm();
  };

  const editSubscriptionPlan = (values: any, resetForm: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name[ar]', values.name_ar);
    formData.append('name[en]', values.name_en);
    formData.append('description[ar]', values.description_ar);
    formData.append('description[en]', values.description_en);
    formData.append('commercial_description[ar]', values.commercial_description_ar);
    formData.append('commercial_description[en]', values.commercial_description_en);

    if (values.downgrade_plans.length > 0) {
      formData.append('fallback_id', (values.downgrade_plans[0] as any)?.value);
    }

    formData.append('_method', 'PUT');

    axiosInstance
      .post(`/admin/plans/${plan?.id}`, formData)
      .then((response) => {
        setLoading(false);
        toast.success(formatMessage({ id: 'subscription plan has been updated successfully!' }), {
          toastId: 'subscription plan has been updated successfully!',
        });
        history.push(`/admin/subscription-plans`);
      })
      .catch((err) => {
        setLoading(false);
        const data = err?.response?.data?.errors;
        const mergedErrors: any = Object.values(data).reduce(
          (accumulator: any, currentValue) => accumulator.concat(currentValue),
          []
        );
        mergedErrors.map((error: string, index: number) => toast.error(error, { toastId: index }));
      });
    resetForm();
  };

  const getPermissionsPlan = useCallback((param) => {
    axiosInstance
      .get(`admin/plans/${param}`)
      .then((response) => {
        setPlan(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const checkNumberOfActivePlans = useCallback(async (value: boolean) => {
    setLoading(true);
    axiosInstance
      .post(`/admin/plans/check-status`)
      .then((response) => {
        setLoading(false);
        formik.setFieldValue('status', value ? '1' : '0');
      })
      .catch((err) => {
        setLoading(false);
        const data = err?.response?.data?.errors;
        const mergedErrors: any = Object.values(data).reduce(
          (accumulator: any, currentValue) => accumulator.concat(currentValue),
          []
        );
        mergedErrors.map((error: string, index: number) => toast.error(error, { toastId: index }));
        formik.setFieldValue('status', '0');
      });
    setLoading(false);
  }, []);

  const setActivePlan = useCallback(
    async (value: boolean) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('is_active', value ? '1' : '0');
      formData.append('_method', 'PUT');

      axiosInstance
        .post(`/admin/plans/${plan?.id}/set-active`, formData)
        .then((response) => {
          setLoading(false);
          getPermissionsPlan(params?.subscriptionPlanId);
        })
        .catch((err) => {
          setLoading(false);
          const data = err?.response?.data?.errors;
          const mergedErrors: any = Object.values(data).reduce(
            (accumulator: any, currentValue) => accumulator.concat(currentValue),
            []
          );
          mergedErrors.map((error: string, index: number) =>
            toast.error(error, { toastId: index })
          );
          formik.setFieldValue('status', '0');
        });

      setLoading(false);
    },
    [plan]
  );

  useEffect(() => {
    if (mode === 'view' || mode === 'edit') {
      getPermissionsPlan(params?.subscriptionPlanId);
    }
  }, []);

  useEffect(() => {
    if ((mode === 'edit' || mode === 'view') && plan) {
      // setDescriptionPlane({
      //   ar: plan?.description.ar,
      //   en: plan?.description.en,
      // });
      formik.setFieldValue('for_embedded_app', plan?.for_embedded_app === 1 ? true : false);
      formik.setFieldValue('order', {
        label: '',
        value: plan?.order,
      });
      formik.setFieldValue('description_ar', plan?.description.ar);
      formik.setFieldValue('description_en', plan?.description.en);

      formik.setFieldValue('name_ar', plan?.name.ar);
      formik.setFieldValue('name_en', plan?.name.en);
      setSubscribedPlans(plan?.features!);
      formik.setFieldValue(
        'downgrade_plans',
        plan?.fallback?.id
          ? [
              {
                label: plan?.fallback?.name,
                value: plan?.fallback?.id,
              },
            ]
          : []
      );
      formik.setFieldValue(
        'monthly_price',
        plan?.prices.find((price: any) => price.period_type === 1)?.price
      );
      formik.setFieldValue(
        'yearly_price',
        plan?.prices.find((price: any) => price.period_type === 2)?.price
      );
      formik.setFieldValue('status', plan?.is_active ? '1' : '0');
      formik.setFieldValue('type', plan?.type);
      formik.setFieldValue('invoice_period', plan?.invoice_period);
      formik.setFieldValue(
        'commercial_description_ar',
        plan?.commercial_description.ar ? plan?.commercial_description.ar : ''
      );
      formik.setFieldValue(
        'commercial_description_en',
        plan?.commercial_description.en ? plan?.commercial_description.en : ''
      );
    }
  }, [plan]);

  /* ------------------------- get plan type data ------------------------ */

  const planTypesOptions = useMemo(
    () =>
      Array.from(PlanTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );
  const ArrangeThePackage = useMemo(() => {
    const list = Array.from([1, 2, 3, 4]).map((item) => {
      return {
        label: item.toString(),
        value: item,
      };
    });
    return list ?? [];
  }, []);

  /* ------------------------- get client data ------------------------ */
  const loadDownGradeOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`admin/plans`, {
          params: {
            name: inputValue,
            per_page: 0,
            type: `3`, // type fallback
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((plan: any) => ({
              ...plan,
              label: plan?.name?.[locale as LOCALE],
              value: plan.id,
            }))
          );
        });
    }, 1000),
    []
  );

  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <FormikProvider value={formik}>
        <Form
          id='create_subscription_plan'
          noValidate
          autoComplete='none'
          style={{ width: '100%' }}
        >
          <div className='m-10'>
            <div className='row fv-row'>
              {locales.map((lang, index) => (
                <div className='col-xl-6' key={index}>
                  <Input
                    required
                    // placeholder={planeName[lang]}
                    placeholder={LocaleTitle({ id: 'plan name', lang })}
                    autoComplete='off'
                    labelId={LocaleTitle({ id: 'plan name', lang })}
                    name={`name_${lang}`}
                    type='text'
                    dir={lang === 'ar' ? 'rtl' : 'ltr'}
                    disabled={mode === 'view'}
                    formik={formik}
                  />
                </div>
              ))}
            </div>
            <div className='row fv-row mt-10'>
              {locales.map((lang, index) => (
                <div className='col-xl-6' key={index}>
                  <TextArea
                    // placeholder={descriptionPlane[lang]}
                    placeholder={LocaleTitle({ id: 'plan description', lang })}
                    autoComplete='off'
                    labelId={LocaleTitle({ id: 'plan description', lang })}
                    name={`description_${lang}`}
                    type='text'
                    dir={lang === 'ar' ? 'rtl' : 'ltr'}
                    rows='6'
                    disabled={mode === 'view'}
                    formik={formik}
                  />
                </div>
              ))}
            </div>
            <div className='row fv-row mt-10'>
              {['ar', 'en'].map((lang) => (
                <div className='col-lg-6'>
                  <TextArea
                    placeholder={LocaleTitle({
                      id: 'Commercial_description',
                      lang,
                    })}
                    labelId={LocaleTitle({ id: 'Commercial_description', lang })}
                    autoComplete='off'
                    name={`commercial_description_${lang}`}
                    type='text'
                    rows='6'
                    disabled={mode === 'view'}
                    formik={formik}
                    dir={lang === 'ar' ? 'rtl' : 'ltr'}
                    id={`commercial_description_${lang}`}
                    // value={commercial_description?.[lang]}
                  />
                </div>
              ))}
            </div>
            <div className='row fv-row mt-10'>
              {/*  ---------------------  'Plan type select-----------------------  */}
              <div className='col-xl-6'>
                <Label msg='Plan type' htmlFor='type' isRequired />
                <Select
                  onChange={(item) => formik.setFieldValue('type', item.value)}
                  onBlur={() => formik.setFieldTouched('type', true)}
                  options={planTypesOptions}
                  placeholder={formatMessage({ id: 'Plan type' })}
                  // isMulti
                  value={planTypesOptions.find((option) => option.value === formik.values?.type)}
                  isDisabled={mode === 'view' || mode === 'edit'}
                  className='react-select smaller'
                  id='type'
                />
                <ErrorMessage name='type' formik={formik} />
              </div>
              {/*  --------------------- end Plan type select-----------------------  */}
            </div>
            <>
              {/*  --------------------- start Paid Plan -----------------------  */}
              {formik.values?.type && formik.values?.type === 1 && (
                <div className='row fv-row mt-10'>
                  <div className='col-xl-6'>
                    {/*  --------------------- DownGrade select-----------------------  */}

                    <Label msg='Downgrade plan' htmlFor='downgrade_plans' />
                    <AsyncSelect
                      key={JSON.stringify(`downgrade_plans`)}
                      onChange={(value) =>
                        value !== null
                          ? formik.setFieldValue('downgrade_plans', [value])
                          : formik.setFieldValue('downgrade_plans', [])
                      }
                      onBlur={() => formik.setFieldTouched('downgrade_plans', true)}
                      value={formik.values?.downgrade_plans}
                      isDisabled={mode === 'view'}
                      className='form-control form-control-md p-0'
                      id='downgrade_plans_id'
                      cacheOptions
                      loadOptions={loadDownGradeOptions}
                      defaultOptions
                      placeholder={formatMessage({
                        id: 'Downgrade plan',
                      })}
                      isClearable
                      menuPlacement='auto'
                      noOptionsMessage={() => {
                        return (
                          <span role='alert' className='text-danger'>
                            {formatMessage({
                              id: 'No options found',
                            })}
                          </span>
                        );
                      }}
                      loadingMessage={() => (
                        <div
                          className='d-flex justify-content-center'
                          id='client_loading_indicator'
                        >
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      )}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 2,
                          paddingBottom: 2,
                          zIndex: 3,
                        }),
                        menuPortal: (base: any) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        noOptionsMessage: (base: any) => ({
                          ...base,
                          color: 'red',
                        }),
                      }}
                      components={{
                        Option: ({ children, ...props }: OptionProps<any, false>) => {
                          return (
                            <components.Option {...props}>
                              <div className='d-flex justify-content-between'>
                                <span>{props.data?.label}</span>
                              </div>
                            </components.Option>
                          );
                        },
                      }}
                    />
                    <ErrorMessage name='downgrade_plans' formik={formik} />

                    {/*  --------------------- end DownGrade select-----------------------  */}
                  </div>
                  <div className='col-xl-6'>
                    {' '}
                    <div className='d-flex flex-row align-items-center m-auto p-10'>
                      <div className='d-flex align-items-center flex-equal fw-row me-4 order-2 mt-2'>
                        <label className='d-flex flex-column mb-0 cursor-pointer'>
                          <span className='d-flex align-items-center me-2'>
                            <span className='form-switch form-switch-custom form-switch-solid me-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                name='status'
                                checked={formik.values?.status === '1' ? true : false}
                                onChange={(event) => {
                                  if (mode === 'create') {
                                    checkNumberOfActivePlans(event.target.checked);
                                  } else {
                                    setActivePlan(event.target.checked);
                                  }
                                }}
                                id='plan_status'
                                disabled={mode === 'view' || mode === 'edit'}
                              />
                            </span>

                            <span className='d-flex flex-column'>
                              <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                {formik.values?.status === '0' && <FormattedMessage id='Hidden' />}
                                {formik.values?.status === '1' && <FormattedMessage id='Enabled' />}
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='row fv-row'>
                    <div className='col-xl-6'>
                      <Input
                        required
                        placeholder={formatMessage({ id: 'Monthly_price' })}
                        autoComplete='off'
                        labelId={formatMessage({ id: 'Monthly_price' })}
                        name={`monthly_price`}
                        type='text'
                        disabled={mode === 'view' || mode === 'edit'}
                        formik={formik}
                      />
                    </div>
                    <div className='col-xl-6'>
                      <Input
                        required
                        placeholder={formatMessage({ id: 'Yearly_price' })}
                        autoComplete='off'
                        labelId={formatMessage({ id: 'Yearly_price' })}
                        name={`yearly_price`}
                        type='text'
                        disabled={mode === 'view' || mode === 'edit'}
                        formik={formik}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/*  --------------------- end Paid Plan -----------------------  */}{' '}
              {/*  --------------------- start Customized Plan -----------------------  */}
              {formik.values?.type && formik.values?.type === 2 && (
                <div className='row fv-row mt-10'>
                  <div className='col-xl-6'>
                    {/*  --------------------- DownGrade select-----------------------  */}

                    <Label msg='Downgrade plan' htmlFor='downgrade_plans' />
                    <AsyncSelect
                      key={JSON.stringify(`downgrade_plans`)}
                      onChange={(value) =>
                        value !== null
                          ? formik.setFieldValue('downgrade_plans', [value])
                          : formik.setFieldValue('downgrade_plans', [])
                      }
                      onBlur={() => formik.setFieldTouched('downgrade_plans', true)}
                      value={formik.values?.downgrade_plans}
                      isDisabled={mode === 'view' || mode === 'edit'}
                      className='form-control form-control-md p-0'
                      id='invoice_client_id'
                      cacheOptions
                      loadOptions={loadDownGradeOptions}
                      defaultOptions
                      placeholder={formatMessage({
                        id: 'Downgrade plan',
                      })}
                      isClearable
                      menuPlacement='auto'
                      noOptionsMessage={() => {
                        return (
                          <span role='alert' className='text-danger'>
                            {formatMessage({
                              id: 'No options found',
                            })}
                          </span>
                        );
                      }}
                      loadingMessage={() => (
                        <div
                          className='d-flex justify-content-center'
                          id='client_loading_indicator'
                        >
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      )}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 2,
                          paddingBottom: 2,
                          zIndex: 3,
                        }),
                        menuPortal: (base: any) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        noOptionsMessage: (base: any) => ({
                          ...base,
                          color: 'red',
                        }),
                      }}
                      components={{
                        Option: ({ children, ...props }: OptionProps<any, false>) => {
                          return (
                            <components.Option {...props}>
                              <div className='d-flex justify-content-between'>
                                <span>{props.data?.label}</span>
                              </div>
                            </components.Option>
                          );
                        },
                      }}
                    />
                    <ErrorMessage name='downgrade_plans' formik={formik} />

                    {/*  --------------------- end DownGrade select-----------------------  */}
                  </div>
                  <div className='col-xl-6'>
                    {' '}
                    <div className='d-flex flex-row align-items-center m-auto p-10'>
                      <div className='d-flex align-items-center flex-equal fw-row me-4 order-2 mt-2'>
                        <label className='d-flex flex-column mb-0 cursor-pointer'>
                          <span className='d-flex align-items-center me-2'>
                            <span className='form-switch form-switch-custom form-switch-solid me-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                name='for_embedded_app'
                                checked={formik?.values?.for_embedded_app}
                                onChange={(event) => {
                                  if (mode === 'create' || mode === 'edit') {
                                    formik.setFieldValue('for_embedded_app', event.target.checked);
                                  }
                                }}
                                disabled={mode === 'view' || mode === 'edit'}
                              />
                            </span>

                            <span className='d-flex flex-column'>
                              <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                <FormattedMessage id='fai embedded' />
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6'>
                    <Label msg='Arrange the package' htmlFor='order' isRequired />
                    <Select
                      onChange={(item) => formik.setFieldValue('order', item)}
                      onBlur={() => formik.setFieldTouched('order', true)}
                      options={ArrangeThePackage}
                      placeholder={formatMessage({ id: 'Arrange the package' })}
                      value={ArrangeThePackage.find(
                        (option) => option?.value === formik.values?.order.value
                      )}
                      isDisabled={mode === 'view' || mode === 'edit'}
                      className='react-select smaller'
                      id='order'
                    />
                    <ErrorMessage name='order' formik={formik} />
                  </div>
                </div>
              )}
              {/*  --------------------- end Customized Plan -----------------------  */}
              {/*  --------------------- start Fallback Plan -----------------------  */}
              {formik.values?.type && formik.values?.type === 3 && (
                <div className='row fv-row mt-10'>
                  <div className='col-xl-6'>
                    {/*  --------------------- DownGrade select-----------------------  */}

                    <Label msg='Downgrade plan' htmlFor='downgrade_plans' />
                    <AsyncSelect
                      key={JSON.stringify(`downgrade_plans`)}
                      onChange={(value) =>
                        value !== null
                          ? formik.setFieldValue('downgrade_plans', [value])
                          : formik.setFieldValue('downgrade_plans', [])
                      }
                      onBlur={() => formik.setFieldTouched('downgrade_plans', true)}
                      value={formik.values?.downgrade_plans}
                      isDisabled={mode === 'view' || mode === 'edit'}
                      className='form-control form-control-md p-0'
                      id='invoice_client_id'
                      cacheOptions
                      loadOptions={loadDownGradeOptions}
                      defaultOptions
                      placeholder={formatMessage({
                        id: 'Downgrade plan',
                      })}
                      isClearable
                      menuPlacement='auto'
                      noOptionsMessage={() => {
                        return (
                          <span role='alert' className='text-danger'>
                            {formatMessage({
                              id: 'No options found',
                            })}
                          </span>
                        );
                      }}
                      loadingMessage={() => (
                        <div
                          className='d-flex justify-content-center'
                          id='client_loading_indicator'
                        >
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      )}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 2,
                          paddingBottom: 2,
                          zIndex: 3,
                        }),
                        menuPortal: (base: any) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        noOptionsMessage: (base: any) => ({
                          ...base,
                          color: 'red',
                        }),
                      }}
                      components={{
                        Option: ({ children, ...props }: OptionProps<any, false>) => {
                          return (
                            <components.Option {...props}>
                              <div className='d-flex justify-content-between'>
                                <span>{props.data?.label}</span>
                              </div>
                            </components.Option>
                          );
                        },
                      }}
                    />
                    <ErrorMessage name='downgrade_plans' formik={formik} />

                    {/*  --------------------- end DownGrade select-----------------------  */}
                  </div>
                  <div className='col-xl-6'>
                    <Input
                      required
                      placeholder={formatMessage({ id: 'Period in days' })}
                      autoComplete='off'
                      labelId={formatMessage({ id: 'Period in days' })}
                      name={`invoice_period`}
                      type='text'
                      disabled={mode === 'view' || mode === 'edit'}
                      formik={formik}
                    />
                  </div>
                </div>
              )}
              {/*  --------------------- end Fallback Plan -----------------------  */}{' '}
            </>
          </div>
          {/*  --------------------- end Accordion select-----------------------  */}

          <div className='m-auto my-5' style={{ width: '95%' }}>
            <div className='accordion' id='accordionExample'>
              {subscribedPlans &&
                subscribedPlans.map((plan, index) => (
                  <div className='accordion-item mt-5' key={index}>
                    <h2
                      className='accordion-header'
                      id='headingOne'
                      onClick={() => setDisplayed(() => _.xor([index], displayed))}
                    >
                      <button
                        className='accordion-button'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseOne'
                        aria-expanded='true'
                        aria-controls='collapseOne'
                      >
                        <i
                          className={`mx-3 far fa-arrow-${locale === 'ar' ? 'left' : 'right'}`}
                        ></i>{' '}
                        {plan.name}
                      </button>
                    </h2>
                    <div
                      id='collapseOne'
                      className={clsx('accordion-collapse collapse', {
                        show: displayed.includes(index),
                      })}
                      aria-labelledby='headingOne'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body' key={plan.id}>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5 flex-grow-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            disabled={mode === 'view' || mode === 'edit'}
                            checked={plan.is_active}
                          />
                          <span className='form-check-label'>{plan.name}</span>
                        </label>
                        {plan.validation_type === 'Number' && (
                          <input
                            className='form-control form-control-sm b-radius-16 basic-input my-1'
                            placeholder={plan.name}
                            value={plan.value}
                            disabled
                          />
                        )}
                      </div>

                      {plan?.children?.map((child, index) => (
                        <div key={index}>
                          <div
                            className=' mt-5'
                            onClick={() => setDisplayed(() => _.xor([index], displayed))}
                          >
                            <div
                              id='collapseOne'
                              aria-labelledby='headingOne'
                              data-bs-parent='#accordionExample'
                            >
                              <div className='accordion-body' key={child.id}>
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 flex-grow-1'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    disabled={mode === 'view' || mode === 'edit'}
                                    checked={child.is_active}
                                  />
                                  <span className='form-check-label'>{child.name}</span>
                                </label>
                                {child.validation_type === 'Number' && (
                                  <input
                                    className='form-control form-control-sm b-radius-16 basic-input my-1'
                                    placeholder={child.name}
                                    value={child.value}
                                    disabled
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              {mode === 'create' && <PlansInCreate />}
            </div>
          </div>

          {/*  --------------------- end Accordion select-----------------------  */}

          {(mode === 'create' || mode === 'edit') && (
            <div className='text-center col-xl-3 d-flex gap-3 m-5'>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-sm w-100 mb-5 btn-primary btn-shadow btn-primary-shadow mt-4'
                // disabled={formik.isSubmitting}
              >
                {!loading && (
                  <span className='indicator-label'>
                    <FormattedMessage id='save' />
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <FormattedMessage id='Please wait...' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button
                type='button'
                id='kt_sign_up_submit'
                className='btn btn-sm w-100 mb-5 btn-danger btn-shadow btn-danger-shadow mt-4'
                // disabled={formik.isSubmitting}
                onClick={() => history.push('/admin/subscription-plans')}
              >
                {!loading && (
                  <span className='indicator-label'>
                    <FormattedMessage id='Cancel' />
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <FormattedMessage id='Please wait...' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};

const ViewSubscriptionPlans: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'View Subscription plans' })}</PageTitle>
      <SubscriptionPlansWrapper mode='view' />
    </div>
  );
};
const EditSubscriptionPlans: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'View Subscription plans' })}</PageTitle>
      <SubscriptionPlansWrapper mode='edit' />
    </div>
  );
};
const CreateSubscriptionPlans: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'create new subscription' })}</PageTitle>
      <SubscriptionPlansWrapper mode='create' />
    </div>
  );
};

export { ViewSubscriptionPlans, EditSubscriptionPlans, CreateSubscriptionPlans };
